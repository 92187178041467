import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import BlockContent from '@sanity/block-content-to-react';

const serializers = {
  marks: {
    // eslint-disable-next-line react/prop-types
    code: ({ children }) => (
      <pre>
        <code dangerouslySetInnerHTML={{ __html: children[0] }} />
      </pre>
    ),
    // eslint-disable-next-line react/prop-types
    link: ({ children, mark }) => {
      // eslint-disable-next-line react/prop-types
      const { href } = mark;

      return (
        <>
          {href ? (
            <a href={href || ''} target="_blank" rel="noreferrer">
              {children}
            </a>
          ) : (
            // eslint-disable-next-line
            <a>{children}</a>
          )}
        </>
      );
    },
  },
};

const StyledContent = styled(BlockContent)`
  p {
    line-height: 1.4;
    ${tw`font-300 tracking-20`}
    ${({ noSpacing }) => !noSpacing && tw`mb-20`}
    ${({ blog }) => (blog ? tw`text-22` : tw`text-16`)}
    
    strong { ${tw`font-400`} }
  }

  a { ${tw`text-redBlog underline`} }
  
  figure {
    ${tw`relative left-1/2 mt-28 mb-8 transform -translate-x-1/2 md:w-750 md:mb-14`}
  }
  
  ul, ol {
    ${tw`my-25 pl-20 md:pl-40`}

    li { ${tw`ml-22 mb-8`} }
  }
  
  ul { ${tw`list-disc`} }
  
  ol { ${tw`list-decimal`} }
`;

const BlockSanity = ({
  content, noSpacing, blog, ...props
}) => (
  <StyledContent
    blocks={content}
    serializers={serializers}
    imageOptions={{ w: 750, fit: 'max' }}
    projectId="68nx9iqu"
    dataset="production"
    noSpacing={noSpacing}
    blog={blog}
    {...props}
  />
);

BlockSanity.defaultProps = {
  noSpacing: false,
  blog: false,
};

BlockSanity.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.any.isRequired,
  noSpacing: PropTypes.bool,
  blog: PropTypes.bool,
};

export default BlockSanity;
